<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-6 col-12">Add Voter</div>
  </div>
  <div class="layout-content-section">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="nav-link active" id="aadhar-details-tab" data-bs-toggle="tab" data-bs-target="#aadharvoterdetail"
          type="button" role="tab" aria-controls="aadhar-tab-details" aria-selected="true">
          Add using Aadhar
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="manual-entry-tab" data-bs-toggle="tab" data-bs-target="#voterreport" type="button"
          role="tab" aria-controls="customer-details" aria-selected="false" @click="redirectaddusingmanualurl()">
          Add Manually
        </button>
      </li>
    </ul>
    <div class="voter-detail-box-outer">
      <div class="row">
        <div class="col-12">
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="aadharvoterdetail" role="tabpanel"
              aria-labelledby="aadhar-details-tab">
              <div class="company-section-outer" ref="section" style="height: calc(100vh - 196px); padding: 16px 16px 0;">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="row">
                      <div class="col-lg-3 col-md-3 col-12">
                        <div class="custom-form-group">
                          <label class="form-label">Mobile No.<span class="text-danger">*</span></label>
                          <div class="input-group custom-input-group">
                            <div class="col-lg-4 col-md-4 col-12">
                              <Multiselect v-model="check.countrycode" :options="countryCodeList" :searchable="true"
                                label="label" placeholder="Select" disabled
                                class="multiselect-custom custom-title-multiselect" :canClear="false"
                                :closeOnSelect="true" :object="true" noOptionsText="No Result" :canDeselect="false" />
                            </div>
                            <div class="col-lg-8 col-md-8 col-12">
                              <input type="text" v-model="aadharmobile" class="form-control" id="formMobileNoInput"
                                placeholder="Enter Mobile No." :disabled="hideaadharmobile || showaadharloader" maxlength="10"
                                @keypress="onlyNumberMobile" @paste="onPasteMobile" autocomplete="off" />
                            </div>
                          </div>
                          <span class="custom-error" v-if="mobilealertmsg">
                            {{ mobilealertmsg }}
                          </span>
                        </div>
                      </div>
                      <div class="
                              col-lg-2 col-md-2 col-12
                            " v-if="!hideaadharmobile">
                        <div class="custom-form-group" style="margin-top: 26px;">
                          <button type="button" class="header-btn-outer btn btn-primary" :style="{ width: '105px' }"
                            @click="checkaadharmobileinsystem()" :disabled="aadharmobile.length != 10 || showaadharloader">
                            <span v-if="!showaadharloader">Get Details</span>
                            <div class="
                                    spinner-border
                                    text-light
                                    custom-spinner-loader-btn
                                  " role="status" v-if="showaadharloader">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </button>
                        </div>
                      </div>
                      <div class="col text-end">
                        <button type="button" v-if="hideaadharmobile" style="margin-top: 26px;"
                          class="clear-addvoter-btn btn btn-primary" @click="reloadaadhartab()">
                          Clear All
                        </button>
                        <button v-if="addmorevoterflag && this.addmorebtncount < 7" type="button" style="margin-top: 26px;"
                          class="clear-addvoter-btn btn btn-primary" @click="addMoreAadharDetails()" :disabled="addmorevoterInputFlag">
                          Add More Voter
                        </button>
                        <button type="button" v-if="displaymyvoterbtnflag" style="margin-top: 26px;"
                          class="clear-addvoter-btn btn btn-primary" @click="redirectmyvoterurl()">
                          Redirect To Voter List
                        </button>
                      </div>
                    </div>
                    <div class="row" v-if="aadharinputdisplayflag">
                      <div class="col-lg-3 col-md-3 col-12">
                        <div class="custom-form-group">
                          <label class="form-label">Aadhar No.<span class="text-danger">*</span></label>
                          <input type="text" v-model="check.aadharcardnumber" :disabled="searchInputHideFlag"
                            class="form-control text-uppercase" id="formaadharcardinput" placeholder="Enter Aadhar No."
                            autocomplete="off" maxlength="12" @paste="onPasteMobile" @keypress="onlyNumber" />
                          <div class="custom-error" v-if="v$.check.aadharcardnumber.$error && invalidAadharMsg == ''">
                            {{ v$.check.aadharcardnumber.$errors[0].$message }}
                          </div>
                          <span class="custom-error" v-if="otpsenmobmsg">
                            {{ otpsenmobmsg }}
                          </span>
                          <span class="custom-error" v-if="invalidAadharMsg">
                            {{ invalidAadharMsg }}
                          </span>
                        </div>
                      </div>
                      <div class="
                              col-lg-2 col-md-2 col-12
                            " v-if="!searchBtnHideFlag">
                        <div class="custom-form-group" style="margin-top: 26px;">
                          <button type="button" class="header-btn-outer btn btn-primary" :style="{ width: '100px' }"
                            @click="searchExistAadharStatus()" :disabled="searchInputHideFlag || showsearchloader">
                            <span v-if="!showsearchloader">Send OTP</span>
                            <div class="
                                    spinner-border
                                    text-light
                                    custom-spinner-loader-btn
                                  " role="status" v-if="showsearchloader">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </button>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12" v-if="displayMobilePopUp">
                        <div class="custom-form-group">
                          <label class="form-label">Enter OTP<span class="text-danger">*</span></label>
                          <input type="text" v-model="aadharotp" class="form-control otp-input-space"
                            id="formaadharcardinput" placeholder="Enter OTP" autocomplete="off" maxlength="6"
                            minlength="6" @keypress="onlyNumber" @paste="onPasteMobile" :disabled="mobile_otp_status == 1" />
                          <div class="d-flex align-items-center justify-content-between">
                            <span class="custom-error" v-if="mobileotperr">
                              {{ mobileotperr }}
                            </span>
                            <span v-if="timerMobileStatus" class="otp-counter-text mt-1">{{
                              timerMobileInterval
                            }}</span>
                            <button @click="resendAadharOtp()" class="btn btn-link aadhar-resent-otp-btn"
                              v-if="resentotpbtnstatus" :style="{ width: '80px' }" :disabled="showresentloader">
                              <span v-if="!showresentloader">Resend OTP</span>
                              <div class="spinner-border custom-spinner-icon-loader-btn" role="status"
                                v-if="showresentloader">
                                <span class="visually-hidden">Loading...</span>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="
                              col-lg-2 col-md-2 col-12
                            " v-if="displayMobilePopUp">
                        <div class="custom-form-group" style="margin-top: 26px;">
                          <button type="button" class="header-btn-outer btn btn-success" @click="verifyAadharOtp()"
                            :style="{ width: '80px' }"
                            :disabled="aadharotp.length != 6 || mobile_otp_status == 1 || showverifyloader">
                            <span v-if="!showverifyloader">Verify</span>
                            <div class="
                                    spinner-border
                                    text-light
                                    custom-spinner-loader-btn
                                  " role="status" v-if="showverifyloader">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </button>
                        </div>
                      </div>

                    </div>
                    <div class="row" v-if="systemlimiterrormsg">
                      <div class="col-lg-12 col-md-12 col-12">
                        <span class="custom-img-error">{{ systemlimiterrormsg }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <!-- <div class="col-lg-12 col-md-12 col-12" v-if="aadhardivdisplayflag">
                        <div class="text-success">
                          {{ this.votermessage }}
                        </div>
                      </div> -->
                      <div class="col-lg-12 col-md-12 col-12" v-if="is_user == 0">
                        <div class="row" v-if="setprimarynumberfk == 1">
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group d-flex align-items-center">
                              <div class="flex-shrink-0">
                                <div class="from-label-value">Voter with Aadhar No. <span class="text-primary">{{
                                  this.primaryaadharvalue
                                }}</span> is already registered with <span class="text-primary">+91-{{ this.secondarymobvalue }}</span> (P).</div>
                                <div class="from-label-value">Do you want to make <span class="text-primary">+91-{{
                                  this.primarymobvalue }}</span>
                                  (S)
                                  this number as primary for communication purpose on WhatsApp?</div>
                              </div>
                              <div class="flex-grow-1 ms-4 d-flex align-items-center">
                                <div>
                                  <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" v-model="switchbtnmob"
                                      name="primary-option-yes" id="setprimary" value="1">
                                    <label class="form-check-label from-label-value" for="setprimary">Yes, Set as a
                                      Primary</label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" v-model="switchbtnmob"
                                      name="primary-option-yes" id="setsecondary" value="0">
                                    <label class="form-check-label from-label-value" for="setsecondary">No, Add this as Secondary</label>
                                  </div>
                                </div>
                                <button type="button" class="clear-addvoter-btn btn btn-primary ms-3"
                                  @click="confirmPrimaryModalOpen(switchbtnmob)"
                                  :disabled="switchbtnmob == '' || switchbtnmob == null">Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="aadharDisplayDataShow">
                      <!-- Aadhar Via GOv API detail display start here -->
                      <div class="col-lg-12 col-md-12 col-12" v-if="aadharvoterGovDetails != ''">
                        <div class="col-lg-12 col-md-12 col-12" v-if="aadharvoterGovDetails.ak29 == 3">
                            <div class="voter-already-user-status">
                              <i class="pi pi-info-circle me-2"></i>{{ this.check.aadharcardnumber }} <span>There is already an User in the system associated with this Aadhar number.</span>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12" v-if="aadharvoterGovDetails.ak29 == 2">
                            <div class="voter-already-user-status">
                                <i class="pi pi-info-circle me-2"></i>{{ this.check.aadharcardnumber }} <span>There is already an Karyakartas in the system associated with this Aadhar number.</span>
                            </div>
                        </div>
                        <div class="voter-exit-box-outer mb-3">
                          <div class="row">
                            <div class="
                          col-lg-2 col-md-2 col-12
                          d-flex
                          align-items-center
                        ">
                              <div class="customer-label-group">
                                <div class="d-flex align-items-center">
                                  <div>
                                    <img v-if="aadharvoterGovDetails.ak32 == null ||
                                      aadharvoterGovDetails.ak32 == ''
                                      " src="/assets/images/profile.svg" class="rounded bg-light me-auto d-block"
                                      alt="" style="width: 72px; height: 72px;" />
                                    <img :src="aadharvoterGovDetails.ak32" class="rounded bg-light me-auto d-block" alt=""
                                      style="width: 72px; height: 72px;" v-if="aadharvoterGovDetails.ak32 != null ||
                                        aadharvoterGovDetails.ak32 != ''
                                        " />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                  <div class="customer-label-group mb-2">
                                    <label class="form-label">Name</label>
                                    <div class="from-label-value text-capitalize">
                                      {{
                                        aadharvoterGovDetails.ak5
                                        ? aadharvoterGovDetails.ak5
                                        : "N/A"
                                      }}
                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                  <div class="customer-label-group mb-2">
                                    <label class="form-label">Aadhar No.</label>
                                    <div class="from-label-value">
                                      {{
                                        aadharvoterGovDetails.ak36
                                        ? aadharvoterGovDetails.ak36
                                        : "N/A"
                                      }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                  <div class="customer-label-group mb-2">
                                    <label class="form-label">Gender</label>
                                    <div class="from-label-value">
                                      {{
                                        aadharvoterGovDetails.ak22
                                        ? aadharvoterGovDetails.ak22 == 1
                                          ? "Male"
                                          : aadharvoterGovDetails.ak22 == 2
                                            ? "Female"
                                            : "Others"
                                        : "N/A"
                                      }}
                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                  <div class="customer-label-group mb-2">
                                    <label class="form-label">Date of Birth</label>
                                    <div class="from-label-value">
                                      {{ format_date(aadharvoterGovDetails.ak18) }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-2 col-md-2 col-12 text-end" v-if="recentgetdata">
                              <button type="button" @click="confirmUpdateModalOpen(aadharvoterGovDetails)"
                                class="btn custom-view-detail-btn" :disabled="showupdateloader"
                                :style="{ width: '70px' }">
                                <span v-if="!showupdateloader">Update</span>
                                <div class="spinner-border custom-spinner-icon-loader-btn" role="status"
                                  v-if="showupdateloader">
                                  <span class="visually-hidden">Loading...</span>
                                </div>
                              </button>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">House</label>
                                <div class="from-label-value text-capitalize">
                                  {{
                                    aadharvoterGovDetails.aa27
                                    ? aadharvoterGovDetails.aa27
                                    : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Landmark</label>
                                <div class="from-label-value text-capitalize">
                                  {{
                                    aadharvoterGovDetails.aa28
                                    ? aadharvoterGovDetails.aa28
                                    : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Street</label>
                                <div class="from-label-value text-capitalize">
                                  {{
                                    aadharvoterGovDetails.aa26
                                    ? aadharvoterGovDetails.aa26
                                    : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Location</label>
                                <div class="from-label-value text-capitalize">
                                  {{
                                    aadharvoterGovDetails.aa22
                                    ? aadharvoterGovDetails.aa22
                                    : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Post Office</label>
                                <div class="from-label-value text-capitalize">
                                  {{
                                    aadharvoterGovDetails.aa16
                                    ? aadharvoterGovDetails.aa16
                                    : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Area</label>
                                <div class="from-label-value text-capitalize">
                                  {{
                                    aadharvoterGovDetails.ak44
                                    ? aadharvoterGovDetails.ak44
                                    : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">City</label>
                                <div class="from-label-value text-capitalize">
                                  {{
                                    aadharvoterGovDetails.ak46
                                    ? aadharvoterGovDetails.ak46
                                    : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Sub Dist</label>
                                <div class="from-label-value text-capitalize">
                                  {{
                                    aadharvoterGovDetails.aa25
                                    ? aadharvoterGovDetails.aa25
                                    : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">State</label>
                                <div class="from-label-value text-capitalize">
                                  {{
                                    aadharvoterGovDetails.ak48
                                    ? aadharvoterGovDetails.ak48
                                    : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-8 col-md-8 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Address</label>
                                <div class="from-label-value text-capitalize">
                                  {{
                                    aadharvoterGovDetails.ak42
                                    ? aadharvoterGovDetails.ak42
                                    : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Pincode</label>
                                <div class="from-label-value">
                                  {{
                                    aadharvoterGovDetails.ak97
                                    ? aadharvoterGovDetails.ak97
                                    : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Aadhar Via Gov API detail display end here -->
                      <!-- Aadhar Via GOv API detail display start here -->
                      <Divider v-if="aadharvoterDetails != '' && aadharvoterGovDetails != ''" />
                      <div class="col-lg-12 col-md-12 col-12" v-if="aadharvoterDetails != ''">
                        <div class="col-lg-12 col-md-12 col-12" v-if="aadharvoterDetails.ak29 == 3">
                            <div class="voter-already-user-status">
                              <i class="pi pi-info-circle me-2"></i>{{ this.check.aadharcardnumber }} <span>There is already an User in the system associated with this Aadhar number.</span>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12" v-if="aadharvoterDetails.ak29 == 2">
                            <div class="voter-already-user-status">
                                <i class="pi pi-info-circle  mr-2"></i>{{ this.check.aadharcardnumber }} <span>There is already an Karyakartas in the system associated with this Aadhar number.</span>
                            </div>
                        </div>
                        <div class="voter-exit-box-outer mb-3">
                          <div class="row">
                            <div class="
                          col-lg-2 col-md-2 col-12
                          d-flex
                          align-items-center
                        ">
                              <div class="customer-label-group">
                                <div class="d-flex align-items-center">
                                  <div>
                                    <img
                                    v-if="(aadharvoterDetails.ak90 == null || aadharvoterDetails.ak90 == '') && (aadharvoterDetails.ak33 == null || aadharvoterDetails.ak33 == '')"
                                    src="/assets/images/profile.svg" class="
                                      rounded
                                      bg-light
                                      me-auto
                                      d-block
                                    " alt="" style="width: 72px; height: 72px;" />
                                  <img v-if="aadharvoterDetails.ak90 != null && aadharvoterDetails.ak90 != ''"
                                    :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/voterphoto/' + aadharvoterDetails.ak90"
                                    class="
                                      rounded
                                      bg-light
                                      me-auto
                                      d-block
                                      voter-photo-img
                                    " alt="" />
                                  <img
                                    v-if="(aadharvoterDetails.ak33 != null && aadharvoterDetails.ak33 != '') && (aadharvoterDetails.ak90 == null || aadharvoterDetails.ak90 == 'NULL' || aadharvoterDetails.ak90 == '')"
                                    :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/aadharphoto/' + aadharvoterDetails.ak33"
                                    class="
                                      rounded
                                      bg-light
                                      me-auto
                                      d-block
                                      voter-photo-img
                                    " alt="" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                  <div class="customer-label-group mb-2">
                                    <label class="form-label">Name</label>
                                    <div class="from-label-value text-capitalize">
                                      {{
                                        aadharvoterDetails.ak5
                                        ? aadharvoterDetails.ak5
                                        : "N/A"
                                      }}
                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                  <div class="customer-label-group mb-2">
                                    <label class="form-label">Aadhar No.</label>
                                    <div class="from-label-value">
                                      {{
                                        aadharvoterDetails.ak36
                                        ? aadharvoterDetails.ak36
                                        : "N/A"
                                      }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                  <div class="customer-label-group mb-2">
                                    <label class="form-label">Gender</label>
                                    <div class="from-label-value">
                                      {{
                                        aadharvoterDetails.ak22
                                        ? aadharvoterDetails.ak22 == 1
                                          ? "Male"
                                          : aadharvoterDetails.ak22 == 2
                                            ? "Female"
                                            : "Others"
                                        : "N/A"
                                      }}
                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                  <div class="row">
                                    <div class="col-lg-6 col-md-6 col-12">
                                      <div class="customer-label-group mb-2">
                                        <label class="form-label">Date of Birth</label>
                                        <div class="from-label-value">
                                          {{ format_date(aadharvoterDetails.ak18) }}
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-12">
                                      <div class="customer-label-group mb-2">
                                        <label class="form-label">Last Updated Date</label>
                                        <div class="from-label-value">
                                          {{ format_date(aadharvoterDetails.ak137) }}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-2 col-md-2 col-12 text-end" v-if="updatebtnstatus">
                              <button type="button" @click="confirmUpdateModalOpen(aadharvoterDetails)"
                                class="btn custom-view-detail-btn" :disabled="showupdateloader"
                                :style="{ width: '70px' }">
                                <span v-if="!showupdateloader">Update</span>
                                <div class="spinner-border custom-spinner-icon-loader-btn" role="status"
                                  v-if="showupdateloader">
                                  <span class="visually-hidden">Loading...</span>
                                </div>
                              </button>
                            </div>
                            <div class="col-lg-2 col-md-2 col-12 text-end"
                              v-if="aadharvoterDetails.ak36 == null || aadharvoterDetails.ak36 == ''">
                              <button type="button" @click="addNewAadharDetails(aadharvoterDetails)"
                                class="btn custom-view-detail-btn" :disabled="showupdateloader"
                                :style="{ width: '70px' }">
                                <span v-if="!showupdateloader">Update</span>
                                <div class="spinner-border custom-spinner-icon-loader-btn" role="status"
                                  v-if="showupdateloader">
                                  <span class="visually-hidden">Loading...</span>
                                </div>
                              </button>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">House</label>
                                <div class="from-label-value text-capitalize">
                                  {{
                                    aadharvoterDetails.ak148
                                    ? aadharvoterDetails.ak148
                                    : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Landmark</label>
                                <div class="from-label-value text-capitalize">
                                  {{
                                    aadharvoterDetails.ak50
                                    ? aadharvoterDetails.ak50
                                    : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Street</label>
                                <div class="from-label-value text-capitalize">
                                  {{
                                    aadharvoterDetails.ak147
                                    ? aadharvoterDetails.ak147
                                    : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Location</label>
                                <div class="from-label-value text-capitalize">
                                  {{
                                     "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Post Office</label>
                                <div class="from-label-value text-capitalize">
                                  {{
                                    aadharvoterDetails.ak92
                                    ? aadharvoterDetails.ak92
                                    : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Area</label>
                                <div class="from-label-value text-capitalize">
                                  {{
                                    aadharvoterDetails.ak44
                                    ? aadharvoterDetails.ak44
                                    : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">City</label>
                                <div class="from-label-value text-capitalize">
                                  {{
                                    aadharvoterDetails.ak46
                                    ? aadharvoterDetails.ak46
                                    : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Sub Dist</label>
                                <div class="from-label-value text-capitalize">
                                  {{
                                    aadharvoterDetails.ak146
                                    ? aadharvoterDetails.ak146
                                    : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">State</label>
                                <div class="from-label-value text-capitalize">
                                  {{
                                    aadharvoterDetails.ak48
                                    ? aadharvoterDetails.ak48
                                    : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-8 col-md-8 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Address</label>
                                <div class="from-label-value text-capitalize">
                                  {{
                                    aadharvoterDetails.ak42
                                    ? aadharvoterDetails.ak42
                                    : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Pincode</label>
                                <div class="from-label-value">
                                  {{
                                    aadharvoterDetails.ak97
                                    ? aadharvoterDetails.ak97
                                    : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-12 mt-2" v-if="is_user == 0">
                          <div class="custom-form-group">
                              <label class="form-label d-flex justify-content-between"><span>Select
                                      Tags</span><small class="muted-text text-primary">(Max
                                      5)</small></label>
                              <Multiselect v-model="add.tagname" :disabled="voterassignbtnloader" :options="taggroupList" mode="tags"
                                  :createTag="false" label="label"
                                  class="multiselect-custom form-custom-select text-capitalize"
                                  placeholder="Select" :closeOnSelect="true" :searchable="true" :object="true"
                                  :max="5" />
                          </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-12" v-if="is_user == 0">
                        <div class="custom-form-group">
                          <label class="form-label">Assign Voter to</label>
                          <Multiselect v-model="add.voterowner" :disabled="voterassignbtnloader" :options="voterownerList" :searchable="true" label="label"
                            placeholder="Select" class="multiselect-custom text-capitalize" :canClear="true"
                            :closeOnSelect="true" :object="true" noOptionsText="No Result" appendTo="body" />
                        </div>
                      </div>
                      <div class="col-12 text-end" v-if="is_user == 0">
                        <button type="button" class="btn modal-bulk-next-btn" @click="addVoterTagDetailbtn()" :disabled="add.tagname == null || add.voterowner == '' ||voterassignbtnloader">
                          <span v-if="!voterassignbtnloader">Submit</span>
                          <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="voterassignbtnloader">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </button>
                      </div>
                      <!-- Aadhar Via Gov API detail display end here -->
                    </div>
                    <div class="row" v-if="familylistdetails">
                      <!-- Family Voter List detail display start here -->
                      <div class="col-lg-12 col-md-12 col-12" v-for="familylist  in familylistdetails" :key="familylist">
                        <div class="voter-exit-box-outer mb-3">
                          <div class="row">
                            <div class="
                          col-lg-2 col-md-2 col-12
                          d-flex
                          align-items-center
                        ">
                              <div class="customer-label-group">
                                <div class="d-flex align-items-center">
                                  <div>
                                        <img
                                    v-if="(familylist.ak90 == null || familylist.ak90 == '') && (familylist.ak33 == null || familylist.ak33 == '')"
                                    src="/assets/images/profile.svg" class="
                                      rounded
                                      bg-light
                                      me-auto
                                      d-block
                                    " alt="1" style="width: 72px; height: 72px;" />
                                  <img v-if="familylist.ak90 != null && familylist.ak90 != ''"
                                    :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/voterphoto/' + familylist.ak90"
                                    class="
                                      rounded
                                      bg-light
                                      me-auto
                                      d-block
                                      voter-photo-img
                                    " alt="2" />
                                  <img
                                    v-if="(familylist.ak33 != null && familylist.ak33 != '') && (familylist.ak90 == null || familylist.ak90 == 'NULL' || familylist.ak90 == '')"
                                    :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/aadharphoto/' + familylist.ak33"
                                    class="
                                      rounded
                                      bg-light
                                      me-auto
                                      d-block
                                      voter-photo-img
                                    " alt="3" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                  <div class="customer-label-group mb-2">
                                    <label class="form-label">Name</label>
                                    <div class="from-label-value text-capitalize">
                                      {{
                                        familylist.ak5
                                        ? familylist.ak5
                                        : "N/A"
                                      }}
                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                  <div class="customer-label-group mb-2">
                                    <label class="form-label">Aadhar No.</label>
                                    <div class="from-label-value">
                                      {{
                                        familylist.ak36
                                        ? familylist.ak36
                                        : "N/A"
                                      }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                  <div class="customer-label-group mb-2">
                                    <label class="form-label">Gender</label>
                                    <div class="from-label-value">
                                      {{
                                        familylist.ak22
                                        ? familylist.ak22 == 1
                                          ? "Male"
                                          : familylist.ak22 == 2
                                            ? "Female"
                                            : "Others"
                                        : "N/A"
                                      }}
                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                  <div class="row">
                                    <div class="col-lg-6 col-md-6 col-12">
                                      <div class="customer-label-group mb-2">
                                        <label class="form-label">Date of Birth</label>
                                        <div class="from-label-value">
                                          {{ format_date(familylist.ak18) }}
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-12">
                                      <div class="customer-label-group mb-2">
                                        <label class="form-label">Last Updated Date</label>
                                        <div class="from-label-value">
                                          {{ 
                                            ((familylist.ak137 ? familylist.ak137 : '') ? format_date(familylist.ak137) : 
                                            ((familylist.ak80 ? familylist.ak80 : '') ? format_timestamp(familylist.ak80) : "N/A"))  
                                          }}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-2 col-md-2 col-12 text-end"
                              v-if="((familylist.ak36 == null || familylist.ak36 == '') && familylistdetails.length != 1)">
                              <button type="button" @click="addNewAadharDetails(familylist)"
                                class="btn custom-view-detail-btn" :disabled="showupdateloader"
                                :style="{ width: '70px' }">
                                <span v-if="!showupdateloader">Update</span>
                                <div class="spinner-border custom-spinner-icon-loader-btn" role="status"
                                  v-if="showupdateloader">
                                  <span class="visually-hidden">Loading...</span>
                                </div>
                              </button>
                            </div>
                            <div class="col-lg-2 col-md-2 col-12 text-end" v-if="aadharUpdateBtnDaysMsg">
                                <div class="col-12">
                                  <div class="custom-img-error">{{ this.aadharUpdateBtnDaysMsg }}</div>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-2 col-12 text-end"
                              v-if="(familylist.ak36 != null && familylist.ak36 != '')">
                              <div v-if="!aadharupdatebtnflag">
                                <button type="button" @click="confirmUpdateModalOpen(familylist)"
                                  class="btn custom-view-detail-btn" :disabled="showupdateloader"
                                  :style="{ width: '70px' }">
                                  <span v-if="!showupdateloader">Update</span>
                                  <div class="spinner-border custom-spinner-icon-loader-btn" role="status"
                                    v-if="showupdateloader">
                                    <span class="visually-hidden">Loading...</span>
                                  </div>
                                </button>
                              </div>
                            </div>
                            
                          </div>
                          <div class="row">
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">House</label>
                                <div class="from-label-value text-capitalize">
                                  {{
                                    familylist.ak148
                                    ? familylist.ak148
                                    : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Landmark</label>
                                <div class="from-label-value text-capitalize">
                                  {{
                                    familylist.ak50
                                    ? familylist.ak50
                                    : "N/A"
                                    
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Street</label>
                                <div class="from-label-value text-capitalize">
                                  {{
                                    familylist.ak147
                                    ? familylist.ak147
                                    : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Location</label>
                                <div class="from-label-value text-capitalize">
                                  {{
                                     "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Post Office</label>
                                <div class="from-label-value text-capitalize">
                                  {{
                                    familylist.ak92
                                    ? familylist.ak92
                                    : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Area</label>
                                <div class="from-label-value text-capitalize">
                                  {{
                                    (familylist.ak44 ? familylist.ak44 : "") ? (familylist.ak44) :
                                     ((familylist.ak53 ? familylist.ak53 : "") ? familylist.ak53 : "N/A")
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">City</label>
                                <div class="from-label-value text-capitalize">
                                  {{
                                    (familylist.ak46 ? familylist.ak46 : "") ? (familylist.ak46) :
                                     ((familylist.ak55 ? familylist.ak55 : "") ? familylist.ak55 : "N/A")
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Sub Dist</label>
                                <div class="from-label-value text-capitalize">
                                  {{
                                    familylist.ak146
                                    ? familylist.ak146
                                    : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">State</label>
                                <div class="from-label-value text-capitalize">
                                  {{
                                    (familylist.ak48 ? familylist.ak48 : "") ? (familylist.ak48) :
                                     ((familylist.ak57 ? familylist.ak57 : "") ? familylist.ak57 : "N/A")
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-8 col-md-8 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Address</label>
                                <div class="from-label-value text-capitalize">
                                  {{
                                    (familylist.ak42 ? familylist.ak42 : "") ? (familylist.ak42) :
                                     ((familylist.ak51? familylist.ak51: "") ? familylist.ak51: "N/A")
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Pincode</label>
                                <div class="from-label-value">
                                  {{
                                    familylist.ak97
                                    ? familylist.ak97
                                    : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Aadhar Via Gov API detail display end here -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="tab-pane fade" id="voterusingvoterdetail" role="tabpanel" aria-labelledby="votercard-details-tab">
              <div>Using Voter ID</div>
            </div> -->
            <div class="tab-pane fade" id="voterreport" role="tabpanel" aria-labelledby="manual-entry-tab">
              <div>Using Manual</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- confirmation Update Addhar modal start here -->
  <div class="modal-mask" v-if="confirm_update_popup_status">
    <div class="modal-dialog modal-xs modal-dialog-centered custom-modal-outer confirm-dialog-modal">
      <div class="modal-content text-center">
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="confirm-dialog-header">Confirmation</div>
              <div class="d-none">{{ this.ak1 }}</div>
            </div>
            <div class="col-12">
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
                </div>
                <div class="flex-grow-1 ms-3 confirm-dialog-body">
                  Are you sure you want to proceed?
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 text-end">
            <button type="button" :disabled="showconfirmloaderbtn" class="btn confirm-modal-no-btn" @click="confirmUpdateModalClose()">
              No
            </button>
            <button type="button" class="btn confirm-modal-yes-btn" @click="updateVoterPermission(this.ak1)"
              :disabled="showconfirmloaderbtn">
              <span v-if="!showconfirmloaderbtn">Yes</span>

              <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="showconfirmloaderbtn">
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- confirmation Update Addhar modal end here -->
  <!-- confirmation primary mobile modal start here -->
  <div class="modal-mask" v-if="confirm_primary_popup_status">
    <div class="modal-dialog modal-xs modal-dialog-centered custom-modal-outer confirm-dialog-modal">
      <div class="modal-content text-center">
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="confirm-dialog-header">Confirmation</div>
              <div class="d-none">{{ this.mobilesetstatus }}</div>
            </div>
            <div class="col-12">
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
                </div>
                <div class="flex-grow-1 ms-3 confirm-dialog-body">
                  Are you sure you want to proceed?
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 text-end">
            <button type="button" class="btn confirm-modal-no-btn" @click="confirmPrimaryModalClose()" :disabled="showpriconfirmloaderbtn">
              No
            </button>
            <button type="button" class="btn confirm-modal-yes-btn" @click="setprimarynumberbtn(mobilesetstatus)"
              :disabled="showpriconfirmloaderbtn">
              <span v-if="!showpriconfirmloaderbtn">Yes</span>

              <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                v-if="showpriconfirmloaderbtn">
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- confirmation primary mobile modal end here -->
</template>
<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import moment from 'moment';
import { required, helpers, numeric, maxLength, minLength } from '@vuelidate/validators';
export default {
  data() {
    return {
      v$: useValidate(),
      aadharmobile: '',
      check: {
        countrycode: { value: 101, label: "+91" },
        aadharcardnumber: '',
      },
      countryCodeList: [],
      displayMobilePopUp: false,
      timerMobileInterval: null,
      timerMobileStatus: false,
      timerMobileCount: 120,
      mobileotperr: "",
      aadharotp: "",
      aadharvoterDetails: '',
      aadharvoterGovDetails: '',
      aadharUniqueId: '',
      mobile_otp_status: 0,
      aadhardivdisplayflag: false,
      showotploader: false,
      showverifyloader: false,
      resentotpbtnstatus: false,
      setprimarynumberfk: '',
      is_user: '',
      secondarymobvalue: '',
      switchuncheck: false,
      dynamicTrueActiveValue: 1,
      dynamicFalseInactiveValue: 0,
      secondarymobno: '',
      otpsenmobmsg: '',
      votermessage: '',
      searchInputHideFlag: false,
      aadharDisplayDataShow: false,
      showsearchloader: false,
      showupdateloader: false,
      requestmob: '',
      requestaadhar: '',
      searchBtnHideFlag: false,
      updatebtnstatus: false,
      showresentloader: false,
      switchbtnmob: '',
      voterfkvalue: '',
      primarymobvalue: '',
      primaryaadharvalue: '',
      confirm_update_popup_status: false,
      showconfirmloaderbtn: false,
      invalidAadharMsg: '',
      newaddharfkvalue: '',
      displaymyvoterbtnflag: false,
      confirm_primary_popup_status: false,
      showpriconfirmloaderbtn: false,
      confirmprimarynumber: '',
      confirmsecondarynumber: '',
      mobilesetstatus: '',
      familylistdetails: [],
      systemlimiterrormsg: '',
      hideaadharmobile: false,
      showaadharloader: false,
      aadharinputdisplayflag: false,
      addmorevoterflag: false,
      addmorebtncount: '',
      client_info: '',
      voterstorageimgpath: '',
      addmorevoterInputFlag : false,
      aadharUpdateBtnDaysMsg: '',
      aadharupdatebtnflag : false,
      recentgetdata: true,
      mobilealertmsg: '',
      add: {
        tagname: [],
        voterowner: '',
      },
      voterownerList: [],
      voterassignbtnloader: false,
      loginuserdeafultid: null,
      user_info: '',
      taggroupList: [],
      voteruniqueid: '',
      voteruniquename: '',
    };
  },
  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },
  mounted() {
    this.client_info = JSON.parse(localStorage.client_info);
    this.voterstorageimgpath = this.client_info.maa24;
    this.user_info = JSON.parse(localStorage.user);
    this.loginuserdeafultid = this.user_info.user_id;
  },
  validations() {
    return {
      check: {
        aadharcardnumber: {
          required: helpers.withMessage('Please enter Aadhar No.', required), numeric: helpers.withMessage('Only numbers are allowed', numeric), minLength: helpers.withMessage(
            "Please enter vaild Aadhar No",
            minLength(12)
          ), maxLength: maxLength(12)
        },
      },
      aadharotp: {
        required: helpers.withMessage("Enter OTP", required), minLength: helpers.withMessage(
          "Enter vaild OTP",
          minLength(6)
        ),
        maxLength: maxLength(6),
      },
    };
  },
  methods: {
    getactiveusers(e) {
      this.ApiService.getactiveusers(e).then((data) => {
        if (data.status == 200) {
          this.voterownerList = data.data;
          for (let i = 0; i < this.voterownerList.length; i++) {
            let designation = '';
            if(this.voterownerList[i]['ak84'] != null && this.voterownerList[i]['ak84'] != ''){
              designation = ' ('+this.voterownerList[i]['ak84']+')';
            }
            this.voterownerList[i]['label'] = this.voterownerList[i]['label']+''+designation;
          }
          if (this.loginuserdeafultid != null) {
            if (this.voterownerList.length >= 1) {
              let datafk = this.loginuserdeafultid;
              let vals = this.voterownerList.filter(function (item) {
                return item.value == datafk;
              });
              this.add.voterowner = { value: vals[0].value, label: vals[0].label };
            }
          } else {
            this.add.voterowner = '';
          }
        }else{
          this.voterownerList = null;
        }
      });
    },
    gettags(e) {
      this.ApiService.gettags(e).then((data) => {
        if (data.status == 200) {
          this.taggroupList = data.data;
        } else {
          this.taggroupList = null;
        }
      });
    },
    addNewAadharDetails(e) {
      this.showupdateloader = true;
      this.ak1 = e.ak1;
      this.aadharinputdisplayflag = true;
      this.searchBtnHideFlag = false;
      setTimeout(() => {
        this.showupdateloader = false;
        this.scrollToTop();
      }, 2000);
    },
    redirectaddusingepicurl() {
      this.$router.push("/voters/addusingepic");
    },
    redirectaddusingmanualurl() {
      this.$router.push("/voters/addusingmanual");
    },
    redirectmyvoterurl() {
      this.$router.push("/voters/myvoters");
    },
    getcountrycode(e) {
      this.ApiService.getcountrycode(e).then((data) => {
        if (data.status == 200) {
          this.countryCodeList = data.data;
        }
      });
    },
    confirmPrimaryModalOpen(switchbtnmob) {
      this.mobilesetstatus = switchbtnmob;
      this.confirm_primary_popup_status = true;
    },
    confirmPrimaryModalClose() {
      this.confirm_primary_popup_status = false;
    },
    checkaadharmobileinsystem() {
      this.aadharUpdateBtnDaysMsg = '';
      this.showaadharloader = true;
      this.ApiService.checkMobileExists({ ak24: this.aadharmobile }).then((items) => {
        if (items.success === true) {
          this.aadhardivdisplayflag = true;
          this.familylistdetails = items.data;
          this.addmorebtncount = items.count;
          var successMsg = items.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          // this.votermessage = items.message;
          this.showaadharloader = false;
          this.aadharinputdisplayflag = false;
          if(items.data.length == 1 && (items.data[0].ak36 == '' || items.data[0].ak36 == null)){
            this.aadharinputdisplayflag = true;
            this.addmorevoterInputFlag = true;
            this.ak1 = items.data[0].ak1;
            if(items.data[0].update == 0){
              this.aadharupdatebtnflag = true;
              if(items.data[0].update_days != 30) {
                  this.aadharUpdateBtnDaysMsg = 'You can update Aadhar Card details after ' + (30 - items.data[0].update_days) + ' Days';
              }
            }else{
              this.aadharupdatebtnflag = false;
              this.aadharUpdateBtnDaysMsg = '';
            }
          }else if(items.data.length == 1 && (items.data[0].ak36 != '' || items.data[0].ak36 != null)){
            this.aadharinputdisplayflag = false;
            this.addmorevoterInputFlag = false;
            if(items.data[0].update == 0){
              this.aadharupdatebtnflag = true;
              if(items.data[0].update_days != 30) {
                  this.aadharUpdateBtnDaysMsg = 'You can update Aadhar Card details after ' + (30 - items.data[0].update_days) + ' Days';
              }
            }else{
              this.aadharupdatebtnflag = false;
              this.aadharUpdateBtnDaysMsg = '';
            }
          }else{
            this.ak1 = '';
            this.aadharinputdisplayflag = false;
            this.addmorevoterInputFlag = false;
          }
          this.hideaadharmobile = true;
          this.addmorevoterflag = true;        
          this.mobilealertmsg = '';  
          this.gettags();
          this.getactiveusers();
        } else {
          if (items.status == 422) {
            this.mobilealertmsg = items.message;
            this.showaadharloader = false;
            this.hideaadharmobile = false;
          } 
          else {
            this.mobilealertmsg = '';
            this.familylistdetails = null;
            this.showaadharloader = false;
            this.aadharinputdisplayflag = true;
            this.aadhardivdisplayflag = false;
            this.hideaadharmobile = true;
            this.addmorevoterflag = false;
          }
          this.gettags();
          this.getactiveusers();
        }
      });
    },
    confirmUpdateModalOpen(e) {
      this.ak1 = e.ak1;
      this.check.aadharcardnumber = e.ak36;
      this.confirm_update_popup_status = true;
    },
    confirmUpdateModalClose() {
      this.confirm_update_popup_status = false;
    },
    updateVoterPermission(e) {
      this.showconfirmloaderbtn = true;
      this.ak1 = e;
      this.update = 1;
      this.ak24 = this.aadharmobile;
      this.aadhar_number = this.check.aadharcardnumber;
      this.ApiService.generateaadharotp({ ak1: this.ak1, ak24: this.ak24, aadhar_number: this.aadhar_number, update: this.update }).then((data) => {
        if (data.success === true) {
          this.aadharinputdisplayflag = true;
          this.aadharUniqueId = data.data.ak1;
          this.showconfirmloaderbtn = false;
          this.displayMobilePopUp = true;
          var successMsg = data.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.searchBtnHideFlag = true;
          this.startMobileTimer();
          this.timerMobileStatus = true;
          this.aadharotp = "";
          this.otpsenmobmsg = data.message;
          this.updatebtnstatus = false;
          this.confirm_update_popup_status = false;
          this.votergovconfirmdata = [];
          this.votercardnumber = '';
          this.showloader = false;
        } else {
          this.aadharinputdisplayflag = false;
          this.otpsenmobmsg = '';
          this.mobileotperr = data.message;
          var errorMsg = data.message;
          this.$toast.open({
            message: errorMsg,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          this.updatebtnstatus = true;
          this.confirm_update_popup_status = false;
          this.systemlimiterrormsg = data.message;
        }
      });
    },
    // Step 1 Search
    searchExistAadharStatus() {
      this.v$.check.$validate();
      let fields = {};
      if (this.ak1) {
        fields["ak1"] = this.ak1;
      }
      fields["ak24"] = this.aadharmobile;
      fields["aadhar_number"] = this.check.aadharcardnumber;
      if (!this.v$.check.$error) {
        this.searchInputHideFlag = true;
        this.showsearchloader = true;
        this.ApiService.generateaadharotp(fields).then((data) => {
          if (data.success === true) {
              this.invalidAadharMsg = '';
              this.familylistdetails = [];
              this.voteruniqueid = data.data.ak1;
              this.voteruniquename = data.data.ak5;
                if (data.is_otp === 1) {
                  this.searchBtnHideFlag = true;
                  this.timerMobileStatus = true;
                  this.aadharotp = "";
                  this.otpsenmobmsg = data.message;
                  this.displayMobilePopUp = true;
                  this.invalidAadharMsg = '';
                  this.startMobileTimer();
                  this.newaddharfkvalue = data.data.ak1;
                  this.showsearchloader = false;
                  this.aadharDisplayDataShow = false;
                  var successMsgg = data.message;
                  this.$toast.open({
                    message: successMsgg,
                    type: "success",
                    duration: 3000,
                    position: "top-right",
                  });
                } else {
                  this.aadharDisplayDataShow = true;
                  this.aadharvoterDetails = data.data.aadhardata;
                  this.setprimarynumberfk = data.data.setprimarynumber;
                  this.is_user = data.data.is_user;
                  this.secondarymobvalue = data.data.ak26;
                  this.primarymobvalue = data.data.ak24;
                  this.primaryaadharvalue = data.data.id_number;
                  this.voterfkvalue = data.data.ak1;
                  // this.familylistdetails = data.data.mobiledata;
                  this.showsearchloader = false;
                  // this.votermessage = data.message;
                  this.aadhardivdisplayflag = true;
                  this.updatebtnstatus = false;
                }
          } else {
            var errorMsgg = data.message;
            this.$toast.open({
              message: errorMsgg,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
            this.showsearchloader = false;
            this.invalidAadharMsg = data.message;
            this.searchInputHideFlag = false;
          }
        });
      }
    },
    // step 2 If Exits then call update



    // Step 3 Verify Aadhar OTP
    verifyAadharOtp() {
      this.timerMobileCount = 120;
      this.timerMobileStatus = false;
      this.showverifyloader = true;
      let fields = {};
      fields["ak24"] = this.aadharmobile;
      fields["aadhar_number"] = this.check.aadharcardnumber;
      if (this.ak1) {
        fields["ak1"] = this.ak1;
      }
      if (this.newaddharfkvalue) {
        fields["ak1"] = this.newaddharfkvalue;
      }
      fields["otp"] = this.aadharotp;
      this.ApiService.getaadharcarddetails(fields).then((data) => {
        if (data.success === true) {
          this.aadharvoterGovDetails = data.data;
          this.voteruniqueid = data.data.ak1;
          this.voteruniquename = data.data.ak5;
          this.mobile_otp_status = 1;
          this.otpsenmobmsg = '';
          this.aadharDisplayDataShow = true;
          this.searchBtnHideFlag = false;
          this.showverifyloader = false;
          this.recentgetdata = false;
          var successMsg = data.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.aadharotp = '';
          this.mobileotperr = '';
          this.timerMobileStatus = false;
          this.displayMobilePopUp = false;
          this.searchBtnHideFlag = true;
          this.displaymyvoterbtnflag = true;
          this.timerMobileCount = 0;
        } else {
          this.sendOTPMessage = '';
          var errorMsg = data.message;
          this.$toast.open({
            message: errorMsg,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          this.aadharotp = '';
          this.showverifyloader = false;
          this.mobile_otp_status = 1;
          this.aadhardivdisplayflag = true;
          this.mobileotperr = data.message;
          this.timerMobileCount = 0;
          this.timerMobileStatus = false;
          this.resentotpbtnstatus = false;
          this.displaymyvoterbtnflag = false;
          this.systemlimiterrormsg = data.message;
        }
      });
    },
    resendAadharOtp() {
      this.showresentloader = true;
      // this.ak1 = this.aadharUniqueId;
      this.update = 1;
      this.ak24 = this.aadharmobile;
      this.aadhar_number = this.check.aadharcardnumber;
      this.resend_otp = 1;
      this.ApiService.generateaadharotp({ ak1: this.ak1, ak24: this.ak24, aadhar_number: this.aadhar_number, update: this.update, resend_otp: this.resend_otp }).then((data) => {
        if (data.success === true) {
          this.showupdateloader = false;
          this.displayMobilePopUp = true;
          var successMsg = data.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.searchBtnHideFlag = true;
          this.resentotpbtnstatus = false;
          this.startMobileTimer();
          this.timerMobileStatus = true;
          this.aadharotp = "";
          this.otpsenmobmsg = data.message;
          this.mobileotperr = '';
          this.mobile_otp_status = 0;
          this.showresentloader = false;
        } else {
          this.resentotpbtnstatus = false;
          var errorMsg = data.message;
          this.$toast.open({
            message: errorMsg,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          this.showresentloader = false;
          this.invalidAadharMsg = data.message;
          this.systemlimiterrormsg = data.message;
          this.aadharotp = "";
          this.mobileotperr = '';
          this.otpsenmobmsg = '';
        }
      });
    },
    startMobileTimer() {
      // this.timerMobileCount = 0;
      this.resentotpbtnstatus = false;
      this.timerMobileStatus = true;
      if (this.timerMobileCount > 0) {
        setTimeout(() => {
          this.timerMobileCount--;
          this.startMobileTimer();
        }, 1000);
        const minutes = Math.floor(this.timerMobileCount / 60);
        let seconds = this.timerMobileCount % 60;
        if (seconds < 10) {
          this.timerMobileInterval = `0${minutes}:0${seconds}`;
        } else {
          this.timerMobileInterval = `0${minutes}:${seconds}`;
        }
      } else {
        this.timerMobileCount = 120;
        this.timerMobileStatus = false;
        this.resentotpbtnstatus = true;
        this.aadharotp = '';
        // this.mobileotperr = '';
        this.otpsenmobmsg = '';
        // this.invalidAadharMsg = '';
        setTimeout(() => {
          this.mobile_otp_status = 1;
        }, 480000);
        // 8 minutes set timeout
        this.aadhardivdisplayflag = false;
      }
    },
    setprimarynumberbtn(e) {
      this.showpriconfirmloaderbtn = true;
      this.ak1 = this.voterfkvalue;
      this.ak26 = this.secondarymobvalue;
      this.ak24 = this.aadharmobile;
        this.is_family = 0;
        this.primarymobileset = e;
        this.ApiService.updatprimarymobile({ ak1: this.ak1, ak24: this.ak24, ak26: this.ak26, is_primary: this.primarymobileset, is_family: this.is_family }).then((data) => {
          if (data.success === true) {
            this.showpriconfirmloaderbtn = false;
            var successMsg = data.message;
            this.$toast.open({
              message: successMsg,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            this.confirm_primary_popup_status = false;
            this.setprimarynumberfk = '';
            this.is_user = '';
          } else {
            this.showpriconfirmloaderbtn = false;
            var errorMsg = data.message;
            this.$toast.open({
              message: errorMsg,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
            this.confirm_primary_popup_status = true;
            this.setprimarynumberfk = 1;
          }
        });
    },
    reloadaadhartab() {
      this.aadharmobile = '';
      this.check.aadharcardnumber = '';
      this.displayMobilePopUp = false;
      this.timerMobileInterval = null;
      this.timerMobileStatus = false;
      this.timerMobileCount = 120;
      this.mobileotperr = '';
      this.aadharotp = '';
      this.aadharvoterDetails = '';
      this.aadharUniqueId = '';
      this.mobile_otp_status = 0;
      this.aadhardivdisplayflag = false;
      this.showotploader = false;
      this.showverifyloader = false;
      this.resentotpbtnstatus = false;
      this.setprimarynumberfk = '';
      this.secondarymobvalue = '';
      this.switchuncheck = false;
      this.dynamicTrueActiveValue = 1;
      this.dynamicFalseInactiveValue = 0;
      this.secondarymobno = '';
      this.otpsenmobmsg = '';
      this.votermessage = '';
      this.searchInputHideFlag = false;
      this.aadharDisplayDataShow = false;
      this.showsearchloader = false;
      this.showupdateloader = false;
      this.requestmob = '';
      this.requestaadhar = '';
      this.searchBtnHideFlag = false;
      this.updatebtnstatus = false;
      this.showresentloader = false;
      this.switchbtnmob = '';
      this.voterfkvalue = '';
      this.primarymobvalue = '';
      this.primaryaadharvalue = '';
      this.confirm_update_popup_status = false;
      this.showconfirmloaderbtn = false;
      this.invalidAadharMsg = '';
      this.newaddharfkvalue = '';
      this.familylistdetails = [];
      this.hideaadharmobile = false;
      this.aadharinputdisplayflag = false;
      this.addmorevoterflag = false;
      setTimeout(() => {
        this.v$.$reset();
      }, 0);
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      } else {
        return 'N/A';
      }
    },
    format_timestamp(value) {
        if (value) {
            var localTime = new Date(value * 1000).toUTCString();
            return moment(localTime).format("DD/MM/YYYY @ hh:mm A");
        } else {
            return '-';
        }
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
    },
    onPasteMobile(event) {
      let mobvalue = event.clipboardData.getData('text');
      if (/^[0-9]+$/.test(mobvalue)) return true;
      else event.preventDefault();
    },
    onlyNumberMobile($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }

      var mobileInput = document.getElementById("formMobileNoInput");
      if ((mobileInput.value == '' || mobileInput.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)) {
        $event.preventDefault();
      }
    },
    addMoreAadharDetails() {
      if(this.aadharinputdisplayflag == false){
        this.aadharinputdisplayflag = true;
        this.addmorevoterInputFlag= false;
      }else{
        this.addmorevoterInputFlag = true;
      }   
      this.check.aadharcardnumber = '';
      this.familylistdetails = null;
    },
    scrollToTop() {
      this.$refs.section.scrollTo(0, 0);
    },
    addVoterTagDetailbtn() {
      this.voterassignbtnloader = true;
      let fields = {};
      fields["ak1"] = this.voteruniqueid;
      fields["ak5"] = this.voteruniquename;
      if (this.add.tagname != null && this.add.tagname != '') {
        fields["tagname"] = JSON.stringify(this.add.tagname);
      }
      if (this.add.voterowner) {
        fields["ak102"] = this.add.voterowner.value;
        fields["ak104"] = this.add.voterowner.label;
      } else {
        fields["ak102"] = '';
        fields["ak104"] = '';
      }
      this.ApiService.updateVoterTagsAssign(fields).then((data) => {
          if (data.status == 200) {
              this.voterassignbtnloader = false;
              var successMsg = data.message;
              this.$toast.open({
                  message: successMsg,
                  type: "success",
                  duration: 3000,
                  position: "top-right",
              });
              setTimeout(() => {
                  this.v$.$reset();
              }, 0);
              this.add.tagname = null;
              this.add.voterowner = '';
              this.reloadaadhartab();
          } else {
              this.voterassignbtnloader = false;
              var errorMsg = data.message;
              this.$toast.open({
                  message: errorMsg,
                  type: "error",
                  duration: 3000,
                  position: "top-right",
              });
          }
      });
    },
  },
};
</script>
<style scoped>
.voter-detail-box-outer {
  margin-bottom: 15px;
  border: 1px solid #e0e5ed;
  border-radius: 0px 0px 4px 4px;
  border-top: 0;
}

.customer-label-group {
  margin-bottom: 16px;
}

.customer-label-group .form-label {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 13px;
  margin-bottom: 6px;
}

.customer-label-group .from-label-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
}

.company-section-outer {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  height: 522px;
}

.company-section-outer::-webkit-scrollbar {
  display: block;
}

.company-section-outer::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

.company-section-outer::-webkit-scrollbar-thumb {
  background-color: rgb(113 115 119 / 49%);
  border-radius: 3px;
}

.voter-exit-box-outer {
  padding: 16px 16px 0;
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
}

.voter-already-user-status {
  padding: 8px 16px;
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
  margin-bottom: 15px;
  color: #63957d;
  font-weight: 700;
  font-family: "AcuminPro-SemiBold";
}

.clear-addvoter-btn.btn {
  background: #5266E5 0% 0% no-repeat padding-box;
  border-color: #5266E5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #FFFFFF;
  font-size: 13px;
  line-height: 20px;
  padding: 6px 15px;
  margin-left: 8px;
  font-family: 'AcuminPro-Regular';
  height: 34px;
}

.aadhar-resent-otp-btn {
  letter-spacing: 0px;
  color: #3362db;
  font-size: 14px;
  font-family: "AcuminPro-Regular";
  padding: 0;
  text-decoration: none;
}

.aadhar-resent-otp-btn:focus-visible {
  outline: 0;
}

.aadhar-resent-otp-btn:focus {
  box-shadow: none;
}

.modal-bulk-next-btn.btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-color: #5266e5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  line-height: 20px;
  padding: 6px 35px;
  font-family: "AcuminPro-Regular";
}

.modal-bulk-next-btn:focus {
  box-shadow: none;
}</style>